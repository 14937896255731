ACTIVE_RETURN:
  DATA_TYPE:
    BENCHMARK: 'Bench'

GLOBAL:
  STRATEGY: 'QIS'
  THEMATIC: 'Custom Basket'
  BENCHMARK: 'Benchmark'
  STRATEGY_TYPE_NAME:
    STRATEGY: 'QIS'
    PRIVATE_STRATEGY: 'My QIS'
    THEMATIC: 'Custom Basket | Custom Baskets'
    PRIVATE_THEMATIC: 'My Custom Basket | My Custom Baskets'
    BENCHMARK: 'Benchmark | Benchmarks'
    GENERAL_STRATEGY: 'QIS'
    GENERAL_THEMATIC: 'Custom Basket | Custom Baskets'
